import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Navbar from '../organisms/Navbar'

const Container = styled.div`
  padding-right: 3rem;
  padding-left: 3rem;
`

const Title = styled.h1`
  text-align: left;
  font-family: 'Source Sans Pro';
  color: #03c;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  line-height: 1.5;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 300;
  margin: 3rem 0 0;
`

const Content = styled.div`
  h3 {
    text-align: left;
    font-family: 'Source Sans Pro';
    color: #03c;
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    line-height: 1.5;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 3.5rem;
  }

  p {
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    font-family: 'Source Sans Pro';
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1rem;
    color: #505050;
  }
`

const Template = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <>
      <Navbar isWhite={true} />
      <Container>
        <Title>{frontmatter.title}</Title>
        <Content dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    </>
  )
}

Template.propTypes = {
  data: PropTypes.object.isRequired
}

export default Template

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`
